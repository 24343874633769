<template>
  <ExportPanel
    @local-export="exportGameSave(save_key)"
    @cloud-export="uploadGameSave(save_key)"
    :uploading="uploading"
    :save_hash="save_hash"
  />
</template>

<script>
import ExportPanel from "./components/ExportPanel";

import { getGameSaveEmularity, uploadCloud, localDownload } from "@/utils/save";

export default {
  name: "EmularityExportSaveModal",
  inject: ["save_key"],
  components: {
    ExportPanel,
  },
  data: function () {
    return {
      save_hash: null,
      uploading: false,
    };
  },
  methods: {
    clearState: function () {
      this.save_hash = null;
      this.uploading = false;
    },
    exportGameSave: async function (key) {
      try {
        const exportObject = await getGameSaveEmularity(key);

        const json = JSON.stringify(exportObject);
        const blob = new Blob([json], { type: "application/json" });

        localDownload(blob, key);
      } catch (e) {
        this.$bvToast.toast(`存档导出失败，请重新尝试。${e}`, {
          title: "存档导出失败❌",
          autoHideDelay: 5000,
          solid: true,
          variant: "danger",
        });
      }
    },

    uploadGameSave: async function (key) {
      this.uploading = true;
      this.save_hash = null;
      await this.$nextTick();
      try {
        const exportObject = await getGameSaveEmularity(key);
        this.save_hash = await uploadCloud(exportObject);
      } catch (e) {
        this.$bvToast.toast(`存档上传至云端失败，请尝试下载存档至本地。${e}`, {
          title: "存档上传失败❌",
          autoHideDelay: 5000,
          solid: true,
          variant: "danger",
        });
      } finally {
        this.uploading = false;
      }
    },
  },
};
</script>