<template>
  <b-container>
    <b-row class="save-action">
      <file-icon size="1x" class="mr-1" />
      <a @click="$emit('local-export')" class="card-link" role="button">
        下载存档至本地
      </a>
    </b-row>

    <b-row class="mt-1 save-action">
      <upload-cloud-icon size="1x" class="mr-1" />
      <a @click="$emit('cloud-export')" class="card-link" role="button">
        上传存档至云端
      </a>
    </b-row>

    <b-row v-if="uploading" class="mt-1 save-action">
      <b-spinner small variant="primary" class="mr-1" />
      上传中，请稍后...
    </b-row>

    <div v-if="save_hash">
      <b-row class="mt-1 save-action">
        <check-circle-icon size="1x" class="mr-1" />
        <span>存档上传成功，存档 ID 为：</span>
      </b-row>
      <b-row class="mt-1">
        <code>{{ save_hash }}</code>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { UploadCloudIcon, FileIcon, CheckCircleIcon } from "vue-feather-icons";

export default {
  name: "ExportPanel",
  components: {
    UploadCloudIcon,
    FileIcon,
    CheckCircleIcon,
  },
  props: ["uploading", "save_hash"],
};
</script>

<style scoped>
.save-action {
  align-items: center;
}
</style>
