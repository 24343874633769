import { openDB } from "idb";

export default async function (dbName) {
  const storeName = dbName; 

  const db = await openDB(dbName);

  const keys = await db.getAllKeys(storeName);

  const dataObject = {};

  for (const key of keys) {
    const buffer = await db.get(storeName, key);
    if (buffer instanceof ArrayBuffer) {
      const base64 = Buffer.from(buffer).toString("base64");
      const value = `data:application/octet-stream;base64,${base64}`;
      dataObject[key] = value;
    }
  }

  const exportObject = {
    data: {
      [storeName]: dataObject,
    },
    db: dbName,
  };

  return exportObject;
}
