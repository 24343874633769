<template>
  <ExportPanel
    @local-export="exportGameSave(save_key)"
    @cloud-export="uploadGameSave(save_key)"
    :uploading="uploading"
    :save_hash="save_hash"
  />
</template>

<script>
import { getGameSaveJsDos, uploadCloud, localDownload } from "@/utils/save";
import { getSave } from "@/utils/js-dos";

import ExportPanel from "./components/ExportPanel";

export default {
  name: "JsDosExportSaveModal",
  inject: ["save_key", "jsdos_save"],
  components: {
    ExportPanel,
  },
  data: function () {
    return {
      save_hash: null,
      uploading: false,
    };
  },
  methods: {
    clearState: function () {
      this.save_hash = null;
      this.uploading = false;
    },
    exportGameSave: async function (key) {
      try {
        await this.jsdos_save();
        const save = await getSave(key);
        const blob = new Blob([save], { type: "application/zip" });

        localDownload(blob, key);
      } catch (e) {
        this.$bvToast.toast(`存档导出失败，请重新尝试。${e}`, {
          title: "存档导出失败❌",
          autoHideDelay: 5000,
          solid: true,
          variant: "danger",
        });
      }
    },

    uploadGameSave: async function (key) {
      this.uploading = true;
      this.save_hash = null;
      await this.$nextTick();
      try {
        const exportObject = await this.getSaveJson(key);
        this.save_hash = await uploadCloud(exportObject);
      } catch (e) {
        console.error(e);
        this.$bvToast.toast(`存档上传至云端失败，请尝试下载存档至本地。${e}`, {
          title: "存档上传失败❌",
          autoHideDelay: 5000,
          solid: true,
          variant: "danger",
        });
      } finally {
        this.uploading = false;
      }
    },

    getSaveJson: async function (save_key) {
      await this.jsdos_save();
      return await getGameSaveJsDos(save_key);
    },
  },
};
</script>