import { getSave, jsdos_save_db, jsdos_save_store_name } from "@/utils/js-dos";

export default async function (key) {
  const save = await getSave(key);
  const base64 = Buffer.from(save).toString("base64");
  const save_string = `data:application/zip;base64,${base64}`;

  const exportObject = {
    db: jsdos_save_db,
    data: {
      [jsdos_save_store_name]: {
        [key]: save_string,
      },
    },
  };

  return exportObject;
}
