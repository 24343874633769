import { lightFormat } from "date-fns";
import { fileSave } from "@/utils/fileSave";

export default (blob, key) => {
  const ext = blob.type.split("/")[1];
  const date = new Date();
  const date_string = lightFormat(date, "yyyy-MM-dd_HH-mm-ss");
  const fileName = `${key}_${date_string}.${ext}`

  fileSave(blob, {fileName})
};
