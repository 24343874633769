import importGameSave from "./importGameSave";
import { putSave } from "@/utils/js-dos";
import { getLogger } from "@/utils/log";

const logger = getLogger(["backend","save"]);

export default async function importGameSaveFromFile(file, save_key) {
    if (file.name.endsWith(".json")) {
        await importGameSaveFromFileJson(file)
    }
    else if (file.name.endsWith(".zip")) {
        await importGameSaveFromFileZip(file, save_key)
    }
    else {
        throw new Error("不支持的文件类型，需要 json 或 zip.");
    }
}

async function importGameSaveFromFileJson(file) {
    const jsonString = await file.text();
    logger.log("Read game save json successfully.");
    const importObject = JSON.parse(jsonString);
    await importGameSave(importObject);
}

async function importGameSaveFromFileZip(file, save_key) {
    const buffer = await file.arrayBuffer();
    logger.log("Read game save zip successfully.");
    await putSave(buffer, save_key);
}
