import { gzip } from "pako";

export default async (exportObject) => {
    const upload_url = "https://api-dos.zczc.cz/saves";
    const json = JSON.stringify(exportObject);
    // const blob = new Blob([json], { type: "application/json" });
    const blob = new Blob([gzip(json)], { type: "application/gzip" });

    const formData = new FormData();
    formData.append("save", blob);

    const rawResponse = await fetch(upload_url, {
      method: "POST",
      body: formData,
    });
    const result = await rawResponse.json();

    if (result.code == 200) {
      return result.hash;
    } else {
      throw new Error(result.message);
    }
}