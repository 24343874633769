import { openDB } from "idb";
import { putSave, jsdos_save_db } from "@/utils/js-dos";

export default async function importGameSave(importObject) {
  if (importObject.db === jsdos_save_db) {
    await importGameSaveJsDos(importObject);
  } else {
    await importGameSaveEmularity(importObject);
  }
}

async function importGameSaveJsDos(importObject) {
  const save_key = Object.keys(importObject.data.saves)[0];
  const save_string = importObject.data.saves[save_key];
  const base64_str = save_string.split(",")[1];
  const int8_buffer = Buffer.from(base64_str, "base64");
  const buffer = int8_buffer.buffer.slice(
    int8_buffer.byteOffset,
    int8_buffer.byteOffset + int8_buffer.byteLength
  );
  await putSave(buffer, save_key);
}

async function importGameSaveEmularity(importObject) {
  const dbName = importObject["db"];
  const storeName = dbName;

  const db = await openDB(dbName, undefined, {
    upgrade(db) {
      db.createObjectStore(storeName);
    },
  });
  const tx = db.transaction(storeName, "readwrite");
  const store = tx.objectStore(storeName);
  await store.clear();
  const save_data = importObject["data"][storeName];
  const keys = Array.from(Object.keys(save_data));

  for (const key of keys) {
    const base64_str = save_data[key].split(",")[1];
    const int8_buffer = Buffer.from(base64_str, "base64");
    const buffer = int8_buffer.buffer.slice(
      int8_buffer.byteOffset,
      int8_buffer.byteOffset + int8_buffer.byteLength
    );
    await store.put(buffer, key);
    console.log(`Record ${key} added.`);
  }

  await tx.done;
  db.close();
  console.log("Transaction completed.");
}
